import logo from "./logo512.png";
import "./App.css";
import Footer from "./Footer";
import { FaDiscord, FaEnvelope, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Support() {
  // discord icon
  const Discord = () => {
    return (
      <a href="https://Discord.gg/GQves84wBN" target="_blank" className="App-link-icon" rel="noopener noreferrer">
        <FaDiscord color="white" size={32} />
      </a>
    );
  };

  const twitter = () => {
    return (
      <a href="https://twitter.com/dreamphanta" className="App-link-icon" target="_blank" rel="noopener noreferrer">
        <FaTwitter color="white" size={32} />
      </a>
    );
  };

  const Email = () => {
    return (
      <a href="mailto:info@phantadream.ai" className="App-link-icon" target="_blank" rel="noopener noreferrer">
        <FaEnvelope color="white" size={32} />
      </a>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <Link to="/" className="App-link">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
        <p style={{ margin: "60px" }}>Contact us for support through: </p>
        <div>
          {Email()}
          {Discord()}
          {twitter()}
        </div>
      </header>

      <Footer />
    </div>
  );
}
