import React, { useEffect, useState } from "react";
import ImageUploader from "./ImageUploader";
import Parse from "parse/dist/parse.min.js";
import "./FineTune.css";
import SideBar from "./SideBar";
const PARSE_APPLICATION_ID = "s2JueYbVaKF6dOubZHR0SFpeJCYZkNJgYK3l4nKR";
const PARSE_JAVASCRIPT_KEY = "RqHDzcKTaB71eazM27ZITuRfugyeIhC7mGjCWuSA";

export default function FineTune() {
  // create a window for the user to upload their own image with drag and drop
  const [images, setImages] = useState([]);

  var client = new Parse.LiveQueryClient({
    applicationId: PARSE_APPLICATION_ID,
    serverURL: "wss://" + "phantadream.b4a.io/",
    javascriptKey: PARSE_JAVASCRIPT_KEY,
  });
  client.open();

  let query = new Parse.Query("Gallery");
  query.find();
  var subscription = client.subscribe(query);

  subscription.on("create", (image) => {
    // merge image into images array without duplicates
    setImages((images) => {
      // Check if the image already exists in the array
      const isDuplicate = images.find((img) => img.id === image.id);

      // If the image is not a duplicate, merge it into the images array
      if (!isDuplicate) {
        return [...images, image];
      }

      // If the image is a duplicate, return the previous images array without changes
      return images;
    });
  });

  subscription.on("delete", (image) => {
    // remove image from images array
    setImages((images) => {
      // Filter out the image that was deleted
      const filteredImages = images.filter((img) => img.id !== image.id);

      // Return the filtered images array
      return filteredImages;
    });
  });

  useEffect(() => {
    const fetchImages = async () => {
      let query = new Parse.Query("Gallery");
      const results = await query.find();
      setImages(results);
    };

    fetchImages();
  }, []);

  return (
    <div className="row">
      <div className="col-2" id="left">
        <SideBar />
      </div>
      <div className="col" id="right">
        <ul className="img-wrapper">
          <li>
            <ImageUploader />
          </li>
          {images.map((image, index) => (
            <li key={index}>
              <img
                key={index}
                src={image.get("picture").url()}
                alt={`Uploaded ${index}`}
                className="rounded"
                style={{
                  width: "100%",
                }}
              />
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                {image.get("caption")}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
