import React, { useEffect, useRef } from "react";
import "./ImageUploader.css";
import Parse from "parse/dist/parse.min.js";

const ImageUploader = () => {
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    processFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    processFiles(files);
  };

  const processFiles = (files) => {
    const fileArray = Array.from(files);

    fileArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        upload(file.name, imageDataUrl);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleClickToSelect = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const user = Parse.User.current();
    console.log(user.id);
  }, []);

  async function upload(caption, file) {
    // 1. Create a file
    const name = "default.jpg";
    const parseFile = new Parse.File(name, { uri: file });

    // 2. Save the file
    try {
      const responseFile = await parseFile.save();
      const Gallery = Parse.Object.extend("Gallery");
      const gallery = new Gallery();
      gallery.set("picture", responseFile);
      gallery.set("caption", caption);
      const user = Parse.User.current();
      gallery.set("user", user.id);
      user.addUnique("gallery", gallery);
      await user.save();

      await gallery.save();
      console.log("The file has been saved to Back4app.");
    } catch (error) {
      console.log("The file either could not be read, or could not be saved to Back4app.");
    }
  }

  return (
    <div className="image-uploader-container">
      <div className="image-uploader" onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClickToSelect}>
        <input
          type="file"
          accept="image/*"
          multiple
          webkitdirectory=""
          onChange={handleFileInputChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />

        <div className="upload-message">
          <p>Drag and drop files or click to select a folder.</p>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
