import React, { useState } from "react";
import "./App.css";
// import GoogleLogin from "react-google-login";
import Parse from "parse/dist/parse.min.js";
import LandingPage from "./LandingPage";
import { NavLink, Routes, Route } from "react-router-dom";
import FineTune from "./FineTune/FineTune";

export default function Home() {
  const [showLoginModal, setShowLoginModal] = useState(false);

  // discord icon

  const clickLogin = () => {
    setShowLoginModal(true);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  // State variables
  const [currentUser, setCurrentUser] = useState(localStorage.getItem("isLoggedIn") === "true" ? {} : null);

  const doUserLogOut = async function () {
    try {
      await Parse.User.logOut();
      const currentUser = await Parse.User.current();
      if (currentUser === null) {
        console.log("Success! No user is logged in anymore!");
      }
      // Update state variable holding current user
      getCurrentUser();
      localStorage.removeItem("isLoggedIn"); // Clear login status from localStorage
      return true;
    } catch (error) {
      console.log(`Error! ${error.message}`);
      return false;
    }
  };

  // Function that will return current user and also update current username
  const getCurrentUser = async function () {
    const currentUser = await Parse.User.current();
    setCurrentUser(currentUser);

    if (currentUser) {
      localStorage.setItem("isLoggedIn", "true"); // Save login status in localStorage
    }

    return currentUser;
  };

  const handleGoogleLoginLoginResponse = async function (response) {
    // Check if response has an error
    if (response.error !== undefined) {
      console.log(`Error: ${response.error}`);
      return false;
    } else {
      try {
        // Gather Google user info
        const userGoogleId = response.googleId;
        const userTokenId = response.tokenId;
        const userEmail = response.profileObj.email;
        // Try to login on Parse using linkWith and these credentials
        // Create a new Parse.User object
        const userToLogin = new Parse.User();
        // Set username and email to match google email
        userToLogin.set("username", userEmail);
        userToLogin.set("email", userEmail);
        try {
          let loggedInUser = await userToLogin.linkWith("google", {
            authData: { id: userGoogleId, id_token: userTokenId },
          });
          // logIn returns the corresponding ParseUser object
          console.log(`Success! User ${loggedInUser.get("username")} has successfully signed in!`);
          // Update state variable holding current user
          getCurrentUser();
          closeLoginModal();

          return true;
        } catch (error) {
          // Error can be caused by wrong parameters or lack of Internet connection
          alert(`Error! ${error.message}`);
          return false;
        }
      } catch (error) {
        console.log("Error gathering Google user info, please try again!");
        return false;
      }
    }
  };

  const navItem = (name, link) => {
    return (
      <NavLink
        to={link}
        className={({ isActive }) =>
          isActive ? "nav-link mr-3 bg-dark text-light rounded-pill" : "nav-link mr-3 text-dark"
        }
      >
        {name}
      </NavLink>
    );
  };

  // fine tune the model with successful greeting card data
  // generate large batch of greeting cards

  return (
    <div className="App">
      <nav className="navbar navbar-expand-sm flex-sm-nowrap flex-wrap" style={{ height: "var(--navBarHeight)" }}>
        <div className="container-fluid">
          <button
            className="navbar-toggler flex-grow-sm-1 flex-grow-0 me-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <span className="navbar-brand ">
            {/* <NavLink to="" className="text-decoration-none text-dark">
              PhantaDream
            </NavLink> */}
          </span>
          <div className="navbar-collapse collapse justify-content-center" id="navbar">
            {/* {navItem("Discover", "discover")} */}
            {/* {navItem("Fine tune", "finetune")} */}
            {/* {navItem("Create", "create")} */}
          </div>

          {/* <div className="navbar-nav ">
            {currentUser === null ? (
              <button className="login-button" onClick={clickLogin}>
                Login
              </button>
            ) : (
              <button onClick={() => doUserLogOut()} className="login-button">
                Log Out
              </button>
            )}
          </div> */}
        </div>
      </nav>
      <div className="overflow-auto" style={{ height: "calc(100vh - var(--navBarHeight))" }}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="discover" element={<div />} />
          <Route path="finetune" element={<FineTune />} />
          <Route path="create" element={<div />} />
        </Routes>
      </div>

      {/* {showLoginModal && (
        <div className="login-modal">
          <div className="modal-content">
            <span className="close" onClick={closeLoginModal}>
              &times;
            </span>
            <h2>Login</h2>
            <div className="login-modal-content">
              <GoogleLogin
                clientId="108490793456-0flm4qh8ek4cb4krt7e06980o4sjvado.apps.googleusercontent.com"
                // clientId="505695219505-av55em9553p88tt55nmdlhu18aek9led.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={handleGoogleLoginLoginResponse}
                onFailure={handleGoogleLoginLoginResponse}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}
