import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="App-footer">
      <div className="App-footer-content">
        <div className="App-footer-content-left">
          <div className="App-footer-content-left-item">
            <Link className="App-footer-link" to="/privacypolicy">
              Privacy Policy
            </Link>
            <a className="App-footer-link" href="mailto:info@phantadream.ai" target="_blank" rel="noopener noreferrer">
              Contact Us
            </a>
            <Link to="/support" className="App-footer-link">
              Support
            </Link>
          </div>
          <hr />
          <div className="App-footer-content-left-item"> © 2023 PhantaDream</div>
        </div>
      </div>
    </footer>
  );
}
