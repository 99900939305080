import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Privacy from "./Privacy";
import Home from "./Home";
import Support from "./Support";

import Parse from "parse/dist/parse.min.js";
const PARSE_APPLICATION_ID = "s2JueYbVaKF6dOubZHR0SFpeJCYZkNJgYK3l4nKR";
const PARSE_HOST_URL = "https://parseapi.back4app.com/";
const PARSE_JAVASCRIPT_KEY = "RqHDzcKTaB71eazM27ZITuRfugyeIhC7mGjCWuSA";
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Home />} />

        <Route path="support" element={<Support />} />
        <Route path="privacypolicy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
}
