import React from "react";

export default function SideBar() {
  return (
    <div className="justify-content-center">
      <h2 className="text-center">Style name</h2>
      <input type="text" className="form-control" placeholder="Enter style name" />
      <button className="btn btn-dark rounded-pill mt-2">run fine tune</button>
    </div>
  );
}
