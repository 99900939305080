import React from "react";
import MobileStoreButton from "react-mobile-store-button";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import logo from "./logo512.png";
import Footer from "./Footer";

export default function LandingPage() {
  const Discord = () => {
    return (
      <a href="https://Discord.gg/GQves84wBN" target="_blank" className="App-link-icon" rel="noopener noreferrer">
        <FaDiscord color="gray" size={32} />
      </a>
    );
  };

  const twitter = () => {
    return (
      <a href="https://twitter.com/dreamphanta" className="App-link-icon" target="_blank" rel="noopener noreferrer">
        <FaTwitter color="gray" size={32} />
      </a>
    );
  };

  const iOSUrl = "https://apps.apple.com/app/phantadream-ai-image-generator/id6462814021";

  return (
    <div className="Landing-page">
      <div className="Landing-page-content">
        <img src={logo} className="App-logo" alt="logo" />
        <h2 style={{ margin: "60px", color: "gray" }}>Redefine art by fusing the artist and the community with AI</h2>
        <div>
          <a
            href={iOSUrl}
            style={{
              display: "inline-block",
              overflow: "hidden",
              backgroundSize: "contain",
              height: "100%",
              width: "100%",
              marginBottom: "40px",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                height: "60px",
              }}
              src={"app_store_gray.png"}
              alt="Download on the App Store"
            />
          </a>
        </div>
        <div>
          {Discord()}
          {twitter()}
        </div>
      </div>
      <Footer />
    </div>
  );
}
